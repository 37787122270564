import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Notifications from './Notifications';
import Auth from './Auth';
import { ScopeReducer } from './Scope';
import { StyleReducer } from './Style';
import { StyleDependenciesReducer } from './StyleDependencies';
import { SupervisorReducer } from './Supervisor';
import { BatchesReducer } from './Batches/Batches';
import { BatchesMergingReducer } from './Batches/BatchesMerging';
import { BatchesSerializingReducer } from './Batches/BatchesSerializing';
import { BatchesBundlingReducer } from './Batches/BatchesBundling';
import { BatchGroupsReducer } from './BatchGroups';
import { StationsReducer } from './Stations';
import { AQLReducer } from './AQL/AQL';
import { ProductionOrdersReducer } from './Cutting/ProductionOrders';
import { CuttingOrderReducer } from './Cutting/CuttingOrder';
import { ColorsReducer } from './Cutting/Colors';
import { InlineInspectionsReducer } from './InlineInspections';
import { TranslationsReducer } from './Translations';
import { SectionTagsReducer } from './SectionTags';
import { routesReducer } from './Routes';
import { newDashboardReducer } from './newDashboard';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        notifications: Notifications,
        auth: Auth,
        scope: ScopeReducer,
        style: StyleReducer,
        styleDependencies: StyleDependenciesReducer,
        supervisor: SupervisorReducer,
        batches: BatchesReducer,
        batchesMerging: BatchesMergingReducer,
        batchesSerializing: BatchesSerializingReducer,
        batchesBundling: BatchesBundlingReducer,
        batchGroups: BatchGroupsReducer,
        stations: StationsReducer,
        aql: AQLReducer,
        productionOrders: ProductionOrdersReducer,
        cuttingOrder: CuttingOrderReducer,
        colors: ColorsReducer,
        inlineInspections: InlineInspectionsReducer,
        translations: TranslationsReducer,
        sectionTagsData: SectionTagsReducer,
        routes: routesReducer,
        newDashboard: newDashboardReducer,
    });
