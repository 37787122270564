import { SET_THROUGHPUT } from 'constants/ActionTypes';

const initialState = { throughput: { data: null, isLoading: false } };

// actions
export const setThroughput = (payload) => ({
    type: SET_THROUGHPUT,
    payload,
});

// reducer
export const newDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_THROUGHPUT: {
            return { ...state, throughput: action.payload };
        }
        default:
            return state;
    }
};
