import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SettingsSelectorState } from './useSettings.types';

const settingsSelector = createSelector(
    (state: SettingsSelectorState) => state.settings.isDirectionRTL,
    (rtl: boolean) => ({ isDirectionRTL: rtl }),
);

export const useSettings = () => useSelector(settingsSelector);
