import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { errorHandlerNewPattern } from '../../util/globalErrorHandler';
import showNotificationMessage from '../../util/NotificationMessage';
import { isTokenValid } from '../../util/tokenValidation';
import { ErrorResponse } from '../../util/globalErrorHandler/globalErrorHandler.types';
import {
    ApiRequest,
    BaseResponse,
    ParamsWithErrorMessage,
    ParamsWithIgnoredErrorCodes,
} from './apiRequest.types';

export const apiRequest: ApiRequest = async (request, ...params) => {
    const lastParam = params[params.length - 1];
    const isObject = typeof lastParam === 'object';
    const errorMessageFlag = isObject && hasErrorMessage(lastParam);
    const customIgnoredErrorCodes =
        isObject && hasCustomIgnoredErrorCodes(lastParam)
            ? lastParam.customIgnoredErrorCodes
            : undefined;

    if (errorMessageFlag || customIgnoredErrorCodes) {
        params.pop();
    }

    try {
        const response = await request(...params);
        if (!isTokenValid(response)) throw new Error('Invalid token');

        const responseData = response.data as BaseResponse;
        if (responseData.status === false) {
            showNotificationMessage(
                'error',
                responseData.code ? (
                    <IntlMessages id={`shared.error.${responseData.code}`} />
                ) : null,
            );

            return Promise.reject(response);
        }

        return Promise.resolve(response);
    } catch (error) {
        if (
            !errorMessageFlag ||
            (lastParam as ParamsWithErrorMessage).showErrorMessage
        ) {
            errorHandlerNewPattern(
                error as ErrorResponse,
                customIgnoredErrorCodes,
            );
        }
        return Promise.reject(error);
    }
};
function hasErrorMessage(param: unknown): param is ParamsWithErrorMessage {
    return (param as ParamsWithErrorMessage)?.showErrorMessage !== undefined;
}
function hasCustomIgnoredErrorCodes(
    param: unknown,
): param is ParamsWithIgnoredErrorCodes {
    return (
        (param as ParamsWithIgnoredErrorCodes)?.customIgnoredErrorCodes !==
        undefined
    );
}

export function handlePromiseError(error: unknown) {
    console.error('An error occurred:', error);
}
