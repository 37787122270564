import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import React from 'react';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import IntlMessages, { getTooltip } from 'util/IntlMessages';

import getTranslation from '../../util/Language';
import SweetAlert from '../SweetAlert/SweetAlert';

import AgGridButtons from './AgGridButtons';

import styles from './AgGrid.module.scss';
import './nonModularAgGridStyles.scss';

LicenseManager.setLicenseKey(
    'CompanyName=INDOS Corp.,LicensedApplication=garment.io,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-010468,ExpiryDate=28_October_2021_[v2]_MTYzNTM3NTYwMDAwMA==8be036de53287b4b1516215f023992c4',
);

class AgGrid extends React.Component {
    constructor(props) {
        super(props);
        this.mq = window.matchMedia('(max-width : 768px)');
        this.gridApi = null;
        this.gridHeight = 500;
        this.innerGridHeight = 'auto';
        this.headerHeight = 40;
        if (this.props.isDirectionRTL) {
            document.body.classList.remove('rtl');
        }

        this.searchString = this.props.location.search?.split('=');
        this.state = {
            quickFilterText: this.searchString[0].includes('gridSearch')
                ? decodeURIComponent(this.searchString[1])
                : '',
            showDeleteSweetAlert: false,
            paginationPageSize: this.props.pageSizeOptions.includes(100)
                ? 100
                : this.props.pageSizeOptions[0],
            showResetButton: false,
            stateSavingEnabled: true,
            pinnedBottomRowData: [],
            onFirstDataRendered: (e) => e.columnApi.autoSizeAllColumns(),
        };

        if (this.props.detailCellRendererParams) {
            this.innerGrid = {
                detailCellRendererParams: {
                    detailGridOptions: {
                        reactNext: true,
                        skipHeaderOnAutoSize: false,
                        onRowDataChanged: this.autoFit,
                        headerHeight: this.headerHeight,
                        onRowDataUpdated: this.autoFit,
                        onColumnVisible: this.autoFit,
                        animateRows: true,
                        enableRtl: this.props.isDirectionRTL,
                        localeTextFunc: this.localeTextFunc,
                        onGridReady: this.onGridReadyIG,
                        getQuickFilterText: this.state.quickFilterText,
                        getContextMenuItems: this.getContextMenuItems,
                        popupParent: document.querySelector('body'),
                        ...this.props.detailCellRendererParams
                            .detailGridOptions,
                        columnDefs:
                            this.props.detailCellRendererParams
                                .detailGridOptions.columnDefs &&
                            this.formatColumnDefs(
                                this.props.detailCellRendererParams
                                    .detailGridOptions.columnDefs,
                            ),
                        defaultColDef: {
                            detailRowAutoHeight: true,
                            resizable: true,
                            sortable: true,
                            filter: true,
                            menuTabs: [
                                'filterMenuTab',
                                'generalMenuTab',
                                'columnsMenuTab',
                            ],
                            ...this.props.detailCellRendererParams
                                .detailGridOptions.defaultColDef,
                        },
                    },
                    getDetailRowData:
                        this.props.detailCellRendererParams.getDetailRowData,
                    template:
                        `<div style="height: ${this.innerGridHeight} !important; padding: 20px; box-sizing: border-box;">` +
                        '  <div ref="eDetailGrid" style="height: 80%;"></div>' +
                        '</div>',
                },
            };
        }

        // sending any option of these options as a prop overwrites the option in this object
        this.gridOptions = {
            reactNext: true,
            animateRows: true,
            pagination: true,
            skipHeaderOnAutoSize: false,
            headerHeight: this.headerHeight,
            paginationPageSize: this.state.paginationPageSize,
            enableRtl: this.props.isDirectionRTL,
            enableCellTextSelection: false,
            enableRangeSelection: true,
            rowSelection: 'multiple',
            getContextMenuItems: this.getContextMenuItems,
            popupParent: document.querySelector('body'),
            localeTextFunc: this.localeTextFunc,
            context: { componentParent: this },
            onGridReady: this.onGridReady,
            cacheQuickFilter: true,
            pinnedBottomRowData: this.props.getPinnedBottomRow
                ? this.state.pinnedBottomRowData
                : this.props.pinnedBottomRowData,
            ...this.props.gridOptions,
            defaultColDef: {
                autoHeight: true,
                sortable: true,
                filter: true,
                menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
                resizable: true,
                suppressSizeToFit: true,
                suppressMovable: this.mq.matches,
                ...this.props.gridOptions.defaultColDef,
            },
        };
    }

    componentDidMount() {
        if (this.props.isDirectionRTL) {
            document.body.classList.add('rtl');
        }

        const { gridStateName: stateName, match } = this.props;
        const name = `${stateName ? stateName : match.path}`;
        this.gridStateName = `grid-state/${name}`;
        this.IGStateName = `grid-state/${name}-detail`;

        const showResetButton = !!(
            localStorage.getItem(this.gridStateName) ||
            localStorage.getItem(this.IGStateName)
        );

        this.setState({ showResetButton });
    }

    componentDidUpdate = (prevProps) => {
        if (
            prevProps.isPrinting !== this.props.isPrinting &&
            this.props.isPrinting
        ) {
            this.print();
        }
    };

    print = () => {
        let eGridDiv = document.querySelector('#myGrid');
        let eGridSide = document.querySelector('.ag-side-bar');
        if (eGridDiv && eGridDiv.style) {
            eGridDiv.style.width = '';
            eGridDiv.style.height = '';
        }
        if (eGridSide && eGridSide.style) {
            eGridSide.style.opacity = 0;
        }

        if (this.props.doNotExpand === undefined || !this.props.doNotExpand) {
            this.gridOptions.api.forEachNode(function (node) {
                if (node.group) {
                    node.setExpanded(true);
                }
            });
        }

        if (this.props.collapseDetailsForPrinting) {
            this.gridOptions.api.forEachNode(function (node) {
                node.setExpanded(false);
            });
        }

        // this.gridOptions.api.paginationSetPageSize(
        //     this.gridOptions.rowData.length
        // );

        this.gridOptions.api && this.gridOptions.api.setDomLayout('print');

        setTimeout(() => {
            if (this.props.inPrintMode === undefined || !this.props.inPrintMode)
                window.print();
            this.setNormalLayout();
            this.props.afterPrint && this.props.afterPrint();
            this.props.pivotMode && this.gridApi.closeToolPanel();
        }, 2000);
    };

    setNormalLayout = () => {
        let eGridDiv = document.querySelector('#myGrid');
        let eGridSide = document.querySelector('.ag-side-bar');
        if (eGridDiv && eGridDiv.style) {
            eGridDiv.style.width = '100%';
            eGridDiv.style.height = `${this.gridHeight}px`;
        }
        if (eGridSide && eGridSide.style) {
            eGridSide.style.opacity = 1;
        }
        this.gridOptions.api && this.gridOptions.api.setDomLayout(null);
    };

    localeTextFunc = (key, defaultValue) => {
        // to avoid key clash with external keys, we add 'grid' to the start of each key.
        const gridKey = 'shared.grid.' + key;
        if (this.props.isDirectionRTL && !key.includes('aria')) {
            return getTranslation(gridKey);
        }
        return defaultValue;
    };

    handleBottomRow = () => {
        if (!this.gridApi) return;
        if (!this.gridColumnApi) return;
        if (!this.props.getPinnedBottomRow) return;

        const filteredNodes = [];
        this.gridApi.forEachNodeAfterFilterAndSort((rowNode) => {
            if (!rowNode.group) filteredNodes.push(rowNode.data);
        });

        const pinnedBottomRow = this.props.getPinnedBottomRow(
            filteredNodes,
            this.gridApi,
            this.gridColumnApi,
        );
        this.setState({ pinnedBottomRowData: pinnedBottomRow });
    };

    /** @param {GridReadyEvent} params */
    onGridReady = (params) => {
        if (this.props.onGridReady) this.props.onGridReady(params);

        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        if (this.props.pivotMode) params.api.closeToolPanel();

        this.autoFit(params);

        if (this.props.getGridOptions)
            this.props.getGridOptions(this.gridOptions);

        if (this.props.getPinnedBottomRow) this.handleBottomRow();

        if (!this.props.disableUserGridCustomization) {
            setTimeout(() => {
                this.restoreGridState(params, this.gridStateName);
                this.setupGridStateEventHandler(params, this.gridStateName);
            }, 500);
        }
    };

    isSameColNames = (curr, prev) => {
        const currColIds = curr.map((col) => col.colId);
        const prevColIds = new Set(prev.map((col) => col.colId));

        return currColIds.every((id) => prevColIds.has(id));
    };

    /**
     * @param {GridReadyEvent} params
     * @param {string} name
     */
    restoreGridState = (params, name) => {
        if (!params.api) return;

        const gridStateString = localStorage.getItem(name);
        if (gridStateString) {
            try {
                const gridState = JSON.parse(gridStateString);
                const currState = params.columnApi.getColumnState();
                const sameCols = this.isSameColNames(
                    currState,
                    gridState.colState,
                );

                // if column ids changed then update saved grid state
                if (!sameCols) {
                    console.log('column state mismatch: ', {
                        currState,
                        prevState: gridState.colState,
                    });
                    gridState.colState = currState;
                    localStorage.setItem(name, JSON.stringify(gridState));

                    // if there are no other states then just return without doing anything
                    if (
                        Object.values(gridState.filterState).length === 0 &&
                        gridState.groupState.length === 0
                    ) {
                        localStorage.removeItem(name);
                        this.setState({ showResetButton: false });
                        return;
                    }
                } else {
                    if (this.props.pivotMode) {
                        params.columnApi.setPivotMode(false);
                        params.columnApi.applyColumnState({
                            state: gridState.colState,
                            applyOrder: true,
                        });
                        params.columnApi.setPivotMode(true);
                    } else {
                        params.columnApi.applyColumnState({
                            state: gridState.colState,
                            applyOrder: true,
                        });
                    }
                }

                this.setState({ showResetButton: true });
                params.columnApi.setColumnGroupState(gridState.groupState);
                params.api.setFilterModel(gridState.filterState);

                params.api.closeToolPanel();
            } catch (e) {
                console.log(
                    `Failed to set state for grid ${name} with state ${gridStateString}`,
                );
            }
        }
    };

    /**
     * @param {GridApi} gridApi
     * @param {ColumnApi} columnApi
     */
    resetGrid = (gridApi, columnApi) => {
        if (!gridApi || !columnApi) return;
        columnApi.resetColumnState();
        columnApi.resetColumnGroupState();
        gridApi.setFilterModel(null);
        this.autoFit({ api: gridApi, columnApi: columnApi }, true);
    };

    restoreDefaults = () => {
        if (localStorage.getItem(this.gridStateName)) {
            this.resetGrid(this.gridApi, this.gridColumnApi);
        }

        if (localStorage.getItem(this.IGStateName)) {
            this.gridApi.forEachDetailGridInfo((gridInfo) =>
                this.resetGrid(gridInfo.api, gridInfo.columnApi),
            );
        }
        localStorage.removeItem(this.gridStateName);
        localStorage.removeItem(this.IGStateName);
        this.setState({ stateSavingEnabled: false, showResetButton: false });
        setTimeout(() => {
            localStorage.removeItem(this.gridStateName);
            localStorage.removeItem(this.IGStateName);
            this.setState({ stateSavingEnabled: true });
        }, 250);
    };

    /**
     * @param {GridReadyEvent} params
     * @param {string} name
     */
    setupGridStateEventHandler = (params, name) => {
        if (!params.api) return;

        try {
            params.api.addGlobalListener((type, event) => {
                // eslint-disable-next-line default-case
                switch (type) {
                    case 'sortChanged':
                    case 'columnMoved':
                    case 'columnResized':
                    case 'columnVisible':
                    case 'filterModified':
                    case 'filterChanged':
                    case 'displayedColumnsChanged': {
                        if (!this.state.stateSavingEnabled) return;
                        this.setState({ showResetButton: true });

                        const state = JSON.stringify({
                            colState: event.columnApi.getColumnState(),
                            groupState: event.columnApi.getColumnGroupState(),
                            filterState: event.api.getFilterModel(),
                        });
                        localStorage.setItem(name, state);
                    }
                }
            });
        } catch (e) {
            console.log('EVENT LISTENER ERROR: ', e);
        }
    };

    autoFit = (params, force) => {
        const gridStateString = localStorage.getItem(this.gridStateName);

        if (!gridStateString || force) {
            const columnIds = params.columnApi
                .getColumnState()
                .map((c) => c.colId);
            const { bodyWidth, scrollWidth } =
                params.columnApi.columnController;
            const isSlightlyLarger = bodyWidth - scrollWidth < 25;
            if (!isSlightlyLarger && bodyWidth > scrollWidth) {
                params.columnApi.autoSizeColumns(columnIds, false);
            } else {
                params.columnApi.autoSizeColumns(columnIds, false);
                params.api.sizeColumnsToFit();
            }
        }

        this.handleBottomRow();
    };

    onPageSizeChanged = (event) => {
        this.setState({
            paginationPageSize: Number(event.target.value),
        });
        this.gridOptions.api.paginationSetPageSize(Number(event.target.value));
    };

    onColumnResized = (params) => {
        params.api.resetRowHeights();
    };

    onColumnPivotChanged = () => {
        if (this.props.getPinnedBottomRow && this.props.pivotMode)
            this.handleBottomRow();
    };

    onQuickFilterText = (event) => {
        this.setState({ quickFilterText: event.target.value });
    };

    handleEditClick = (node) => {
        this.props.onEdit(node);
    };

    formatColumnDefs = (columnDefs) => {
        if (!columnDefs) return [];
        return columnDefs.map((def) => {
            const newDef = { ...def };

            if (def.headerTextId) {
                delete newDef.headerTextId;
                delete newDef.editRequired;

                newDef.headerName =
                    (def.editRequired ? '* ' : '') +
                    getTranslation(def.headerTextId);
                newDef.headerTooltip = getTooltip(def.headerTextId);
            }
            if (def.headerCheckboxSelection) {
                newDef.headerCheckboxSelectionFilteredOnly = true;
            }
            if (!def.field && def.headerTextId) {
                newDef.field = def.headerTextId.split('.').reverse()[0];
            }

            return newDef;
        });
    };

    handleDeleteClick = (id, data, node) => {
        if (this.props.confirmBeforeDelete) {
            this.setState({
                showDeleteSweetAlert: true,
                nodeIdToDelete: id,
                wholeNodeToDelete: data,
                rowNodeToDelete: node,
            });
        } else {
            this.props.onDelete(id, data, node);
        }
    };

    onGridReadyIG = (params) => {
        this.innerGridApi = params.api;
        this.innerGridColumnApi = params.columnApi;
        this.autoFit(params);

        if (!this.props.disableUserGridCustomization) {
            setTimeout(() => {
                this.restoreGridState(params, this.IGStateName);
                this.setupGridStateEventHandler(params, this.IGStateName);
            }, 250);
        }
    };

    onColumnResizedIG = (params) => {
        params.api.resetRowHeights();
    };

    getContextMenuItems = (params) => [
        'copy',
        'separator',
        'copyWithHeaders',
        'separator',
        'paste',
        'separator',
        'excelExport',
        'separator',
        'csvExport',
    ];

    onFilterChanged = (params) => {
        if (this.props.getPinnedBottomRow) this.handleBottomRow();
        if (this.props.onFilterChanged) this.props.onFilterChanged(params);
        if (this.props.gridOptions.onFilterChanged)
            this.props.gridOptions.onFilterChanged(params);
    };

    onRowDataChanged = (params) => {
        if (this.props.getPinnedBottomRow) this.handleBottomRow();
        this.props.onRowDataChanged(params);
    };

    onRowDataUpdated = (params) => {
        if (this.props.getPinnedBottomRow) this.handleBottomRow();
        this.props.onRowDataUpdated(params);
    };

    onCellValueChanged = (params) => {
        if (this.props.getPinnedBottomRow) this.handleBottomRow();
        if (this.props.onCellValueChanged)
            this.props.onCellValueChanged(params);
    };

    render() {
        const {
            showPageSize,
            pageSizeOptions,
            enableQuickFilter,
            addionalTopComponent,
            title,
            gridHeight,
            secondRowButtons,
            SecondRowEndComponent,
            secondRowEndComponentProps,
            actionsRow,
        } = this.props;

        const {
            quickFilterText,
            showDeleteSweetAlert,
            nodeIdToDelete,
            rowNodeToDelete,
            wholeNodeToDelete,
            paginationPageSize,
            showResetButton,
        } = this.state;

        const propsCopy = { ...this.props };
        delete propsCopy.onGridReady;

        return (
            <div>
                <div className={styles.headerContainer}>
                    {title && <div>{title}</div>}

                    {showPageSize && (
                        <div>
                            <span className='mr-2'>
                                <IntlMessages id='shared.grid.tableSize' />
                            </span>
                            <select
                                value={paginationPageSize}
                                onChange={this.onPageSizeChanged}
                            >
                                {pageSizeOptions.map((number) => (
                                    <option key={number} value={number}>
                                        {number}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div>
                        {showResetButton &&
                            !this.props.hideRestoreDefaultsButton && (
                                <Button
                                    id='restore-defaults-btn'
                                    className='mb-1 mx-3 d-print-none'
                                    size='small'
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.restoreDefaults}
                                >
                                    <IntlMessages id='aggrid.restoreDefaults' />
                                </Button>
                            )}

                        {enableQuickFilter && (
                            <FormControlLabel
                                className={`mr-2 mt-0 ${styles.searchLabel}`}
                                label={getTranslation('shared.search')}
                                control={
                                    <TextField
                                        className='mb-2 mx-1'
                                        type='text'
                                        value={quickFilterText}
                                        name='quickFilter'
                                        onChange={this.onQuickFilterText}
                                    />
                                }
                                labelPlacement='start'
                            />
                        )}
                    </div>

                    {this.props.buttons && (
                        <div>
                            <AgGridButtons
                                buttons={this.props.buttons}
                                gridApi={this.gridApi}
                            />
                        </div>
                    )}
                    {addionalTopComponent && <div>{addionalTopComponent}</div>}
                </div>
                <div className={styles.secondRow}>
                    {secondRowButtons && (
                        <AgGridButtons
                            buttons={secondRowButtons}
                            gridApi={this.gridApi}
                        />
                    )}
                    {SecondRowEndComponent && (
                        <SecondRowEndComponent
                            {...secondRowEndComponentProps}
                            gridApi={this.gridApi}
                        />
                    )}
                </div>
                {actionsRow}
                <div
                    id='myGrid'
                    style={{
                        height: `${gridHeight || this.gridHeight}px`,
                        width: '100%',
                    }}
                    className={clsx(
                        'ag-theme-balham',
                        this.props.isDirectionRTL
                            ? styles.arabicFontAgrid
                            : styles.englishFontAgrid,
                    )}
                >
                    <AgGridReact
                        tooltipShowDelay={0}
                        {...propsCopy}
                        gridOptions={this.gridOptions}
                        columnDefs={
                            this.props.columnDefs
                                ? this.formatColumnDefs(this.props.columnDefs)
                                : this.formatColumnDefs(
                                      this.props.gridOptions.columnDefs,
                                  )
                        }
                        detailCellRendererParams={
                            this.innerGrid &&
                            this.innerGrid.detailCellRendererParams
                        }
                        detailRowAutoHeight={true}
                        pinnedBottomRowData={
                            this.props.getPinnedBottomRow
                                ? this.state.pinnedBottomRowData
                                : this.props.pinnedBottomRowData
                        }
                        onFirstDataRendered={
                            this.props.onFirstDataRendered
                                ? this.props.onFirstDataRendered
                                : this.state.onFirstDataRendered
                        }
                        onColumnPivotChanged={this.onColumnPivotChanged}
                        onRowDataChanged={
                            this.props.onRowDataChanged
                                ? this.onRowDataChanged
                                : this.autoFit
                        }
                        onRowDataUpdated={
                            this.props.onRowDataUpdated
                                ? this.onRowDataUpdated
                                : this.autoFit
                        }
                        onCellValueChanged={this.onCellValueChanged}
                        getContextMenuItems={
                            this.props.getContextMenuItems
                                ? (params) =>
                                      this.props.getContextMenuItems(params)
                                : this.gridOptions.getContextMenuItems
                        }
                        modules={AllModules}
                        onFilterChanged={this.onFilterChanged}
                        quickFilterText={quickFilterText}
                    >
                        {this.props.children}
                    </AgGridReact>
                </div>

                {showDeleteSweetAlert && (
                    <SweetAlert
                        show={showDeleteSweetAlert}
                        title={<IntlMessages id='shared.confirmDeleting' />}
                        confirmBtnStyle='danger'
                        confirmBtnText={<IntlMessages id='button.delete' />}
                        cancelBtnText={<IntlMessages id='button.cancel' />}
                        onConfirm={() => {
                            this.setState({ showDeleteSweetAlert: false });
                            this.props.onDelete(
                                nodeIdToDelete,
                                wholeNodeToDelete,
                                rowNodeToDelete,
                            );
                        }}
                        onCancel={() =>
                            this.setState({
                                showDeleteSweetAlert: false,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = ({
    settings: { locale, isDirectionRTL },
    auth: { authUser },
}) => ({
    authUser,
    isDirectionRTL,
    locale,
});

AgGrid.defaultProps = {
    showPageSize: true,
    pageSizeOptions: [10, 25, 50, 100],
    enableQuickFilter: true,
    confirmBeforeDelete: true,
    gridOptions: {},
    disableUserGridCustomization: false,
    suppressDragLeaveHidesColumns: true,
};

//all functions props accepts the first parameter as Params
AgGrid.propTypes = {
    getGridOptions: PropTypes.func, // accepts gridOptions as params
    enableQuickFilter: PropTypes.bool,
    showPageSize: PropTypes.bool,
    pageSizeOptions: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    gridOptions: PropTypes.object,
    confirmBeforeDelete: PropTypes.bool,
    disableUserGridCustomization: PropTypes.bool,
    gridStateName: PropTypes.string,
    pinnedBottomRowData: PropTypes.arrayOf(PropTypes.object),
    afterPrint: PropTypes.func,
    getContextMenuItems: PropTypes.func,
    immutableData: PropTypes.bool,
    getRowNodeId: PropTypes.func,
    rowSelection: PropTypes.oneOf(['multiple', 'single']),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            ref: PropTypes.any,
            className: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            action: PropTypes.func,
            color: PropTypes.string,
            style: PropTypes.object,
            variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
            disabled: PropTypes.bool,
        }),
    ),
    getPinnedBottomRow: PropTypes.func,
    pagination: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(AgGrid));
