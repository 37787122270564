import showNotificationMessage from 'app/modules/shared/util/NotificationMessage';
import React from 'react';
import IntlMessages from 'util/IntlMessages';
import { ErrorHandlerPattern } from './globalErrorHandler.types';

export const errorHandlerNewPattern: ErrorHandlerPattern = (
    error,
    customIgnoredErrorCodes,
) => {
    const ignoredErrorCodes = customIgnoredErrorCodes ?? ['US05'];
    const errorMessage = error.message.includes('Network Error') ? (
        <IntlMessages id='shared.error.default' />
    ) : (
        error.message
    );
    if (!error.response) return showNotificationMessage('error', errorMessage);

    try {
        if (error.response?.data?.errors) {
            error.response.data.errors.forEach((err) => {
                if (err.code) {
                    if (ignoredErrorCodes.includes(err.code)) return;

                    return showNotificationMessage(
                        'error',
                        <IntlMessages id={`shared.error.${err.code}`} />,
                    );
                }

                showNotificationMessage('error', err.message || '');
            });
        } else {
            return showNotificationMessage('error', errorMessage);
        }
    } catch (err) {
        showNotificationMessage('error', errorMessage);
    }
};

export const errorHandlerOldPattern: ErrorHandlerPattern = (error) => {
    if (!error.response) return showNotificationMessage('error', error.message);

    if (error.response?.data?.error) {
        error.response.data.error.forEach((err) => {
            if (err.code) {
                return showNotificationMessage(
                    'error',
                    <IntlMessages id={`shared.error.${err.code}`} />,
                );
            }

            showNotificationMessage('error', err.msg || '');
        });
    } else {
        return showNotificationMessage('error', error.message);
    }
};
