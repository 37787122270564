import React from 'react';
import ReactDOM from 'react-dom';
import 'app/modules/shared/styles/theme.scss';
import App from './app/App';
import './polyfills';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';

import { COMPANY_KEY } from 'app/modules/devtools/companyUrlSwitcher/utils';
import AuthAPI from 'app/modules/shared/api/AuthAPI';
import {
    apiRequest,
    handlePromiseError,
} from 'app/modules/shared/api/apiRequest';

const store = configureStore();

const rootEl = document.getElementById('app-site');
export const __ON_PRODUCTION__ =
    window.location.hostname.includes('garmentio.com');
export const __ON_PREMISE__ = import.meta.env.VITE_ON_PREMISE === 'true';

if (__ON_PREMISE__) {
    const authApi = new AuthAPI();
    apiRequest(authApi.getCompanySlug)
        .then(({ data }) => {
            localStorage.setItem(COMPANY_KEY, data.slug);
        })
        .catch(handlePromiseError);
}

// Create a reusable render method that we can call more than once
let render = () => {
    // Dynamically import our main App component, and render it
    //const MainApp = require('./MainApp').default;

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path='/' component={App} />
                </Switch>
            </ConnectedRouter>
        </Provider>,
        rootEl,
    );
};

render();
