const languageData = [
    {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
    },
    {
        languageId: 'saudi-arabia',
        locale: 'ar',
        name: 'Arabic',
        icon: 'eg',
    },
    {
        languageId: 'french',
        locale: 'fr',
        name: 'French',
        icon: 'us',
    },
    {
        languageId: 'turkish',
        locale: 'tr',
        name: 'Turkish',
        icon: 'tr',
    },
];
export default languageData;
