const createMemoizer = (fn, { max = Infinity, maxAge = Infinity } = {}) => {
    const cache = new Map();
    const timestampCache = new Map();

    return function (...args) {
        const now = Date.now();
        const key = JSON.stringify(args);

        if (cache.has(key)) {
            const { value, timestamp } = cache.get(key);
            if (now - timestamp < maxAge) {
                return value;
            } else {
                cache.delete(key);
                timestampCache.delete(key);
            }
        }

        const result = fn(...args);
        if (cache.size >= max) {
            // Remove oldest entry
            const oldestKey = Array.from(timestampCache.keys()).reduce(
                (a, b) =>
                    timestampCache.get(a) < timestampCache.get(b) ? a : b,
            );
            cache.delete(oldestKey);
            timestampCache.delete(oldestKey);
        }

        cache.set(key, { value: result, timestamp: now });
        timestampCache.set(key, now);

        return result;
    };
};

// Define your translation function with memoization
const dayInMilliSeconds = 24 * 60 * 60 * 1000;

const memoizedTranslator = createMemoizer(
    (key, userLang, authUser) => {
        const translations =
            localStorage.getItem('translations') &&
            JSON.parse(localStorage.getItem('translations'));

        if (!authUser || !userLang || !translations) return key;

        return translations[userLang][key] || key;
    },
    { max: 1000, maxAge: dayInMilliSeconds },
);

/**
 * Get the translation for a given key
 * @param {string} key - The key to get the translation for
 * @returns {string} The translation for the given key
 */
const getTranslation = (key) => {
    const userLang =
        localStorage.getItem('lang') &&
        JSON.parse(localStorage.getItem('lang')).locale;

    const authUser =
        localStorage.getItem('user_id') &&
        JSON.parse(localStorage.getItem('user_id'))._id;

    return memoizedTranslator(key, userLang, authUser);
};

export default getTranslation;
