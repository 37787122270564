import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ParsedAuthUserSelector } from './useAuthuser.types';

interface RootState {
    auth: {
        authUser: string | null;
    };
}

const parsedAuthUserSelector = createSelector(
    (state: RootState) => state.auth.authUser,
    (user): ParsedAuthUserSelector =>
        user
            ? (JSON.parse(user) as ParsedAuthUserSelector)
            : ({} as ParsedAuthUserSelector),
);

export const useAuthuser = (): ParsedAuthUserSelector =>
    useSelector(parsedAuthUserSelector);
